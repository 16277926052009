// import img1 from '../../upload/purple_bubble.png';
// import img2 from '../../upload/orange_bubble-1.png"';
// import img3 from '../../upload/orange_triangle2.png';
// import img4 from '../../upload/orange_triangle-1.png';
import React from "react"
const Form = () => {
  return (
    <section
      className="elementor-element elementor-element-9a6c678 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="9a6c678"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-d7d3994 elementor-column elementor-col-100 elementor-top-column"
            data-id="d7d3994"
            data-element_type="column"
            data-settings='{"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap padding-form">
                <div
                  className="elementor-element elementor-element-b9a3450 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="b9a3450"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_infinite":"true","avante_ext_infinite_animation":"if_scale","avante_ext_infinite_duration":3,"_position":"absolute","":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={90}
												height={90}
												src={img1}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-36014db elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="36014db"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_infinite":"true","avante_ext_infinite_duration":3,"_position":"absolute","":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_animation":"if_bounce","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={90}
												height={90}
												src={img1}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-45db939 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="45db939"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_infinite":"true","avante_ext_infinite_duration":3,"_position":"absolute","":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_animation":"if_bounce","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={70}
												height={70}
												src={img2}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-8fe9ddf elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="8fe9ddf"
                  data-element_type="widget"
                  data-settings='{"":"true","avante_ext_scrollme_translatey":{"unit":"px","size":200,"sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":-360,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={150}
												height={120}
												src={img3}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-18d063a elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="18d063a"
                  data-element_type="widget"
                  data-settings='{"":"true","avante_ext_scrollme_translatey":{"unit":"px","size":"","sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":-360,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":70,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={150}
												height={120}
												src={img3}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-ca097ab elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="ca097ab"
                  data-element_type="widget"
                  data-settings='{"":"true","avante_ext_scrollme_translatex":{"unit":"px","size":300,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":60,"sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":360,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":360,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={190}
												height={150}
												src={img4}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-ad1748f elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="ad1748f"
                  data-element_type="widget"
                  data-settings='{"":"true","avante_ext_scrollme_translatex":{"unit":"px","size":-200,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":60,"sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":-360,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={190}
												height={150}
												src={img4}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-ceb5275 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="ceb5275"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatex":{"unit":"px","size":-100,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":-50,"sizes":[]},"avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={90}
												height={90}
												src={img1}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-fecfb73 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="fecfb73"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatex":{"unit":"px","size":-100,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":-50,"sizes":[]},"avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={90}
												height={90}
												src={img1}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-fa1c2b9 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                  data-id="fa1c2b9"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","":"true","avante_ext_scrollme_rotatey":{"unit":"px","size":360,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":-200,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":-70,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                  data-widget_type="image.default"
                >
                  {/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={70}
												height={70}
												src={img2}
												className="attachment-large size-large"
												alt=""
											/>
										</div>
									</div> */}
                </div>
                <div
                  className="elementor-element elementor-element-c76081f elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                  data-id="c76081f"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container font-contactus-banner">
                    <h2
                      className="elementor-heading-title elementor-size-default padding-form-judul"
                      id="font-book-app"
                    >
                      <strong>Get in Touch</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-787274d elementor-widget elementor-widget-text-editor"
                  data-id="787274d"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix">
                      <div className="lqd-lines split-unit lqd-unit-animation-done">
                        <p className="p1" id="font-book-app">
                          Let's talk about your project. Please complete the
                          details below and we will be in touch within one
                          business day
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-ba51080 elementor-widget elementor-widget-shortcode"
                  data-id="ba51080"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="shortcode.default"
                >
                  <div className="elementor-widget-container padding-form">
                    <div className="elementor-shortcode">
                      <div
                        role="form"
                        className="wpcf7"
                        id="wpcf7-f5-p5879-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div className="screen-reader-response" />
                        <form
                          className="quform"
                          // action="https://formspree.io/xdowlnnd"
                          action="https://formspree.io/f/mqkjronq"
                          method="post"
                          encType="multipart/form-data"
                          onclick
                        >
                          <div className="quform-elements">
                            <div className="quform-element">
                              <br />
                              <label>Your Name*</label>
                              <span className="wpcf7-form-control-wrap your-name">
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  size={40}
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Name*"
                                  required
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <br />
                              <label>Email Address*</label>
                              <span className="wpcf7-form-control-wrap your-email">
                                <input
                                  id="email"
                                  type="text"
                                  name="email"
                                  size={40}
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Email*"
                                  required
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <br />
                              <label>Your Message*</label>
                              <span className="wpcf7-form-control-wrap your-message">
                                <textarea
                                  id="message"
                                  name="message"
                                  cols={40}
                                  rows={5}
                                  className="input1"
                                  aria-invalid="false"
                                  placeholder="Message*"
                                  defaultValue={""}
                                />
                              </span>
                            </div>
                            {/* Begin Submit button */}
                            <div className="quform-submit">
                              <div className="quform-submit-inner">
                                <button
                                  type="submit"
                                  className="submit-button send-button"
                                >
                                  Send
                                </button>
                              </div>
                              <div className="quform-loading-wrap">
                                <span className="quform-loading" />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Form
